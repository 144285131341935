/**
 * URL query parameters can be strings, arrays of strings, or undefined, which makes type safety complicated.
 * This utility converts arrays of strings to strings and does nothing if the value is undefined.
 *
 * @param {string | string[] | undefined} queryParam
 * @param {string} joinStr String used to join array elements if `queryParam` is an array
 * @returns {string | undefined}
 */
function queryToString(queryParam, joinStr = ' ') {
  return Array.isArray(queryParam) ? queryParam.join(joinStr) : queryParam
}

export { queryToString }
