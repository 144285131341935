import { Page } from './page'

/** @typedef {import('../').pageFn} pageFn */
/** @typedef {import('./graft').Graft} Graft */

class Branch {
  /** @type {Page} */
  #page

  /** @type {pageFn | undefined} */
  #pageFn

  /** @type {Map<string, Branch>} */
  #children

  /**
   * @param {Object} props
   * @param {Page} props.page
   * @param {pageFn} [props.pageFn]
   */
  constructor({ page, pageFn }) {
    this.#page = page
    this.#pageFn = pageFn
    this.#children = new Map()
  }

  /**
   * @param {Page | Branch} child
   * @returns {Branch}
   * @throws
   */
  add(child) {
    if (child instanceof Branch) {
      this.#children.set(child.page.name, child)
      return child
    } else if (child instanceof Page) {
      const branch = new Branch({ page: child })
      this.#children.set(page.name, branch)
      return branch
    } else {
      throw new Error('Unexpected type error. Child must be one of { Page | Branch }')
    }
  }

  /**
   * @returns {Page}
   */
  get page() {
    return this.#page
  }

  /**
   * Always returns a page function
   * @returns {pageFn | undefined}
   */
  get pageFn() {
    return this.#pageFn
  }

  /**
   * 1. Attempt to build static page from this.#pageFn
   * 2. Attempt to build static page from parent's pageFn
   * 3. Return static this.#page
   * @param {string} href
   * @param {string} label
   * @returns {void}
   */
  fn(href, label) {
    if (!this.#pageFn) return
    // render static path
    this.#page = this.#pageFn(href, label)
    // update children
    this.#children.forEach(child => {
      child.page.parent = this.#page
    })
  }

  /**
   * @returns {Map<string, Branch>}
   */
  get children() {
    return this.#children
  }

  /**
   * @param {string} path dot separated page path
   * @param {pathOptions | Array<pathOptions>} [options]
   * @returns {Branch | undefined}
   */
  get(path, options) {
    if (!path) {
      console.warn(`${path} not found in sitemap`)
      return
    }

    // split on dot
    // recursively find child page
    const pathArray = path.split('.')

    let p = pathArray.shift()
    if (!p) {
      console.warn(`${path} not found in Branch ${this.toString()}`)
      return
    }

    let child = this.#children.get(p)

    // little convoluted, but it works -- pass in an array of options to replace multiple $slugs
    for (const p of pathArray) {
      child = child?.children.get(p)
      if (options && Array.isArray(options) && options.length > 0) {
        const shift = options.shift()
        if (!shift) throw new Error('malformed path options, expecting: { href: string, label: string} ')
        const { href, label } = shift
        if (options.length === 0) {
          options = { href, label }
        }
        child?.fn(href, label)
      } else if (options && !Array.isArray(options)) {
        const { href, label } = options
        child?.fn(href, label)
      }
    }

    return child
  }

  /** @param {Graft} graft */
  merge(graft) {
    // need to recursively merge the children, not just shallow merge them
    // ⛔ Don't do this 👉 this.#children = new Map([...this.#children, ...branch.children])

    // require both the current branch and the graft to start from the same level
    if (this.#page.name !== graft.page.name)
      throw new Error('Unable to merge graft into branch. Both must start from the same page.')

    // compare down the graft merging children as needed, then return the branch
    let graftChild = graft.child
    let branchChild = graftChild ? this.#children.get(graftChild.page.name) : undefined
    /** @type {Branch} */
    let parent = this

    while (graftChild && branchChild) {
      parent = branchChild
      graftChild = graftChild.child
      branchChild = graftChild ? branchChild.#children.get(graftChild.page.name) : undefined
    }

    // child exists in graft but not brach, add to branch
    if (graftChild && !branchChild) {
      parent.add(graftChild.toBranch())
    }
  }

  toString() {
    let mapToString = {}
    for (let [key, value] of this.#children) {
      mapToString[key] = JSON.parse(value.toString())
    }

    return JSON.stringify(
      {
        page: JSON.parse(this.#page.toString()),
        pageFn: this.#pageFn && 'pageFn()',
        children: mapToString,
      },
      null,
      2
    )
  }
}

export { Branch }

/**
 * @typedef {object} pathOptions
 * @property {string} pathOptions.href Used when pageFn exists, otherwise ignored
 * @property {string} pathOptions.label Used when pageFn exists, otherwise ignored
 */
