import { Branch } from './branch'
import { Graft } from './graft'
import { Page } from './page'

/** @typedef {import('../').pageFn} pageFn */

class Sitemap {
  /** @type {Branch} */
  #root

  /**
   * @param {Object} props
   * @param {string} [props.label]
   * @param {import('@abs-warranty/fontawesome-svg-core').IconDefinition} [props.icon]
   */
  constructor({ label = 'Home', icon }) {
    const root = new Page({ label, icon })
    this.#root = new Branch({ page: root })
  }

  /**
   * @param {Object} props
   * @param {Page} [props.parent] This Page's parent Page or undefined for no parent
   * @param {string} [props.href] The relative path for this page including a leading slash (/)
   * @param {import('@abs-warranty/fontawesome-svg-core').IconDefinition} [props.icon] This Page's Font Awesome icon
   * @param {string} [props.label] The human friendly name for this page to be displayed in breadcrumbs
   * @param {string} [props.badge] Badge text (e.g: 'new!')
   * @param {string} [props.description] Longer description of the page's purpose
   * @param {boolean} [props.fake] Fake pages don't exist, they're just for organization
   */
  add({ parent, href, icon, label, badge, description, fake }) {
    if (!parent) parent = this.#root.page

    /** @type {Page} */
    let page

    /** @type {pageFn | undefined} */
    let pageFn = undefined

    if (href && label) {
      page = new Page({ parent, href, icon, label, badge, description, fake })
    } else {
      pageFn = (href, label) => new Page({ parent, href, icon, label, badge, description, fake })
      page = pageFn('$slug', 'pageFn')
    }

    // 1. traverse through parents from the bottom up until we find the root, keeping track as we go
    // we know there's a parent, so start with it
    let parents = [parent]
    let localParent = parent

    // look for grandparents
    while (localParent.parent) {
      // push to back of parents array
      parents.push(localParent.parent)
      // go up to next parent
      localParent = localParent.parent
    }

    // 2. create a path from the child page to the parent page
    let graft = new Graft({ page, pageFn })
    for (let p of parents) {
      // create a parent page and add the child
      let parentGraft = new Graft({ page: p, child: graft })
      // go up to next parent
      graft = parentGraft
    }

    // 3. deep merge graft onto this branch
    this.#root.merge(graft)

    return page
  }

  /**
   * Convenience method to access a page by path
   * @param {string} path Dot-separated path e.g.: 'admin.tireProtection.programs.$slug.edit'
   * @param {import('./branch').pathOptions | Array<import('./branch').pathOptions>} [options] `href` and `label` to use when calling pageFn to render a variable
   * @returns {Page | undefined}
   */
  get(path, options) {
    return this.#root.get(path, options)?.page
  }

  /**
   *
   * @param {string} path Dot-separated path e.g.: 'reports.twilio.callHistory'
   * @param {import('./branch').pathOptions | Array<import('./branch').pathOptions>} [options] `href` and `label` to use when calling pageFn to render a variable
   * @returns {Array<{ key: string, value: Page }>}
   */
  getAll(path, options) {
    return Array.from(this.#root.get(path, options)?.children ?? new Map(), ([key, value]) => ({
      key,
      value: value.page,
    }))
  }

  toString() {
    return JSON.stringify({ root: JSON.parse(this.#root.toString()) }, null, 2)
  }
}

export { Sitemap }
