import { faBlenderPhone } from '@abs-warranty/pro-duotone-svg-icons/faBlenderPhone'
import { faCalculator } from '@abs-warranty/pro-regular-svg-icons/faCalculator'
import { faCoin } from '@abs-warranty/pro-regular-svg-icons/faCoin'
import { faEarthAmericas } from '@abs-warranty/pro-regular-svg-icons/faEarthAmericas'
import { faFileChartColumn } from '@abs-warranty/pro-regular-svg-icons/faFileChartColumn'
import { faFileContract } from '@abs-warranty/pro-regular-svg-icons/faFileContract'
import { faFileSpreadsheet } from '@abs-warranty/pro-duotone-svg-icons/faFileSpreadsheet'
import { faFire } from '@abs-warranty/pro-regular-svg-icons/faFire'
import { faHeadSideBrain } from '@abs-warranty/pro-regular-svg-icons/faHeadSideBrain'
import { faPhonePlus } from '@abs-warranty/pro-regular-svg-icons/faPhonePlus'
import { faShieldCheck } from '@abs-warranty/pro-regular-svg-icons/faShieldCheck'
import { faSquarePollVertical } from '@abs-warranty/pro-regular-svg-icons/faSquarePollVertical'
import { faTire } from '@abs-warranty/pro-duotone-svg-icons/faTire'
import { faTire as faTireRegular } from '@abs-warranty/pro-regular-svg-icons/faTire'
import { faTireRugged } from '@abs-warranty/pro-duotone-svg-icons/faTireRugged'
import { faUnicorn } from '@abs-warranty/pro-regular-svg-icons/faUnicorn'
import { faCauldron } from '@abs-warranty/pro-regular-svg-icons/faCauldron'
import { faBookSparkles } from '@abs-warranty/pro-regular-svg-icons/faBookSparkles'
import { faCars } from '@abs-warranty/pro-regular-svg-icons/faCars'
import { faLightbulb } from '@abs-warranty/pro-regular-svg-icons/faLightbulb'
import { faUserHeadset } from '@abs-warranty/pro-regular-svg-icons/faUserHeadset'
import { faDiamondHalfStroke } from '@abs-warranty/pro-regular-svg-icons/faDiamondHalfStroke'
import { faViruses } from '@abs-warranty/pro-regular-svg-icons/faViruses'
import { faBoxArchive } from '@abs-warranty/pro-regular-svg-icons/faBoxArchive'
import { faCarBattery } from '@abs-warranty/pro-regular-svg-icons/faCarBattery'
import { faClock } from '@abs-warranty/pro-regular-svg-icons/faClock'
import { faHeadset } from '@abs-warranty/pro-regular-svg-icons/faHeadset'
import { faHourglassHalf } from '@abs-warranty/pro-regular-svg-icons/faHourglassHalf'
import { faStopwatch } from '@abs-warranty/pro-regular-svg-icons/faStopwatch'

/** @param {import('../lib/sitemap').Sitemap} sitemap */
function reports(sitemap) {
  // /reports
  const reports = sitemap.add({ href: 'reports', icon: faFileSpreadsheet, label: 'Reports' })

  // /reports/twilio
  const twilio = sitemap.add({
    parent: reports,
    href: 'twilio',
    icon: faBlenderPhone,
    label: 'Twilio',
    fake: true,
  })

  // /reports/twilio/archive
  const legacyTwilio = sitemap.add({
    parent: twilio,
    href: 'archive',
    icon: faBoxArchive,
    label: 'Twilio Flex',
    fake: true,
  })

  sitemap.add({ parent: legacyTwilio, href: 'call-records', icon: faPhonePlus, label: 'Call Records' })
  sitemap.add({ parent: legacyTwilio, href: 'surveys', icon: faSquarePollVertical, label: 'Surveys' })

  sitemap.add({ parent: twilio, href: 'agent-status', icon: faUserHeadset, label: 'Agent Status' })
  sitemap.add({ parent: twilio, href: 'answer-time', icon: faHourglassHalf, label: 'Answer Time' })
  sitemap.add({ parent: twilio, href: 'average-talk-time', icon: faStopwatch, label: 'Average Talk Time' })
  sitemap.add({ parent: twilio, href: 'call-minutes', icon: faClock, label: 'Call Minutes' })
  sitemap.add({ parent: twilio, href: 'call-records', icon: faPhonePlus, label: 'Call Records' })
  sitemap.add({ parent: twilio, href: 'duracell', icon: faCarBattery, label: 'Duracell' })
  sitemap.add({ parent: twilio, href: 'surveys', icon: faSquarePollVertical, label: 'Surveys' })
  sitemap.add({ parent: twilio, href: 'technician-data', icon: faHeadset, label: 'Technician Data' })

  // /reports/tire-protection
  const tireProtection = sitemap.add({
    parent: reports,
    href: 'tire-protection',
    icon: faTireRugged,
    label: 'Tire Protection',
    fake: true,
  })

  sitemap.add({ parent: tireProtection, href: 'api-tire', icon: faTire, label: 'API Tires' })
  sitemap.add({ parent: tireProtection, href: 'fury', icon: faFire, label: 'Fury Tires' })
  sitemap.add({ parent: tireProtection, href: 'midas', icon: faCoin, label: 'Midas' })
  sitemap.add({ parent: tireProtection, href: 'ntw', icon: faBookSparkles, label: 'NTW' })
  sitemap.add({ parent: tireProtection, href: 'ntwcardealer', icon: faCars, label: 'NTW Car Dealer' })
  sitemap.add({ parent: tireProtection, href: 't3lite', icon: faLightbulb, label: 'NTW T3 Lite' })
  sitemap.add({ parent: tireProtection, href: 'rac', icon: faFileContract, label: 'Rent-A-Center' })
  sitemap.add({ parent: tireProtection, href: 'smart-tires', icon: faHeadSideBrain, label: 'Smart Tires' })
  sitemap.add({ parent: tireProtection, href: 'tbc', icon: faFileChartColumn, label: 'TBC' })
  sitemap.add({ parent: tireProtection, href: 'thetiresource', icon: faCauldron, label: 'The Tire Source' })
  sitemap.add({ parent: tireProtection, href: 'tirehub', icon: faTireRegular, label: 'TireHub' })
  sitemap.add({ parent: tireProtection, href: 'tires-easy', icon: faShieldCheck, label: 'Tires Easy' })
  sitemap.add({ parent: tireProtection, href: 'transamerica', icon: faEarthAmericas, label: 'Transamerica' })
  sitemap.add({ parent: tireProtection, href: 'unicorn', icon: faUnicorn, label: 'Unicorn Tire' })

  // /reports/motor-club
  const motorClub = sitemap.add({
    parent: reports,
    href: 'motor-club',
    icon: faCars,
    label: 'Motor Club',
    fake: true,
  })

  sitemap.add({ parent: motorClub, href: 'fimc', icon: faDiamondHalfStroke, label: 'FIMC' })

  // /reports/multi-program
  const multiProgram = sitemap.add({
    parent: reports,
    href: 'multi-program',
    icon: faViruses,
    label: 'Multi-Program',
    fake: true,
  })

  sitemap.add({ parent: multiProgram, href: 'quickbooks', icon: faCalculator, label: 'QuickBooks' })
}

export { reports }
