import { faAddressCard } from '@abs-warranty/pro-regular-svg-icons/faAddressCard'
import { faEdit } from '@abs-warranty/pro-regular-svg-icons/faEdit'
import { faFileInvoiceDollar } from '@abs-warranty/pro-regular-svg-icons/faFileInvoiceDollar'
import { faPlus } from '@abs-warranty/pro-regular-svg-icons/faPlus'
import { faTireRugged } from '@abs-warranty/pro-duotone-svg-icons/faTireRugged'

/** @param {import('../lib/sitemap').Sitemap} sitemap */
function tireProtectionPrograms(sitemap) {
  // /tire-protection
  const tireProtection = sitemap.add({
    href: 'tire-protection',
    icon: faTireRugged,
    label: 'Tire Protection Programs',
    fake: true, // TODO: make this a real page, allow multi-program search
    description: 'Create, edit, and view tire protection claims',
  })

  // /tire-protection/:slug
  // TODO: map custom icons
  const program = sitemap.add({
    parent: tireProtection,
    icon: faFileInvoiceDollar,
  })

  // /tire-protection/:slug/claims
  const programClaims = sitemap.add({
    parent: program,
    href: 'claims',
    icon: faFileInvoiceDollar,
    label: 'Claims',
  })

  // /tire-protection/:slug/claims/new
  sitemap.add({
    parent: programClaims,
    href: 'new',
    icon: faPlus,
    label: 'New Claim',
  })

  // /tire-protection/:slug/claim/:slug
  const claim = sitemap.add({
    parent: programClaims,
    icon: faFileInvoiceDollar,
    label: 'View',
  })

  // const viewProgramClaim = (id, program_id, programName) =>
  //   new Page(`/${id}`, `#${id}`, faFileInvoiceDollar, programClaims(program_id, programName))

  // /tire-protection/:slug/claim/:slug/edit
  sitemap.add({
    parent: claim,
    href: 'edit',
    icon: faEdit,
    label: 'Edit Claim',
  })

  // const editProgramClaim = (id, program_id, programName) =>
  //   new Page(`/edit`, `Edit Claim`, faPlus, viewProgramClaim(id, program_id, programName))

  // /tire-protection/:slug/registrations
  const registrations = sitemap.add({
    parent: program,
    href: 'registrations',
    icon: faAddressCard,
    label: 'Registrations',
  })

  // /tire-protection/:slug/registrations/new
  sitemap.add({
    parent: registrations,
    href: 'new',
    icon: faPlus,
    label: 'New Registration',
  })
  // const newProgramRegistration = (program_id, programName) =>

  // /tire-protection/:slug/registrations/:slug
  const registration = sitemap.add({
    parent: registrations,
    icon: faFileInvoiceDollar,
  })

  // /tire-protection/:slug/registrations/:slug/edit
  sitemap.add({
    parent: registration,
    href: 'edit',
    icon: faEdit,
    label: 'Edit Registration',
  })

  // /tire-protection/:slug/details
  sitemap.add({
    parent: program,
    href: 'details',
    label: 'Program Details',
  })
}

export { tireProtectionPrograms }
