import React, { useEffect, useState } from 'react'
import Head from 'next/head'
import Footer from '../components/layout/footer'
import TopNavbar from '../components/navigation/top-navbar'
import SignInCard from '../components/sign-in-card'

function SignInPage() {
  const [fadeIn, setFadeIn] = useState('')

  useEffect(() => {
    setTimeout(() => {
      setFadeIn('opacity-1')
    }, 10)
  }, [])

  return (
    <>
      <Head>
        <title>Call Center Portal | Automotive Business Solutions</title>
      </Head>
      {/* "main" should contain all elements */}
      <main className={`d-flex flex-column w-100 transition-opacity ${fadeIn}`}>
        <TopNavbar />
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col mt-3 mb-5">
              <SignInCard />
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  )
}
export default SignInPage
