import React from 'react'
import { InputRadio, Select } from '@abs-warranty/next-components/form'
import { Form } from 'react-bootstrap'

/**
 *
 * @param {object} props
 * @param {import('react-hook-form').UseFormRegister<import('react-hook-form').FieldValues>} props.register
 * @returns
 */
function UserSettingsForm({ register }) {
  return (
    <>
      <h5>Portal Theme</h5>
      <InputRadio className="ms-4 mb-2" name="settings.theme" value="" label="System" register={register} />
      <InputRadio className="ms-4 mb-2" name="settings.theme" value="light" label="Light" register={register} />
      <InputRadio className="ms-4 mb-2" name="settings.theme" value="dark" label="Dark" register={register} />
      <h5 className="mt-4">Google Maps Theme</h5>
      <InputRadio className="ms-4 mb-2" name="settings.mapsTheme" value="" label="Standard" register={register} />
      <InputRadio className="ms-4 mb-2" name="settings.mapsTheme" value="silver" label="Silver" register={register} />
      <InputRadio className="ms-4 mb-2" name="settings.mapsTheme" value="retro" label="Retro" register={register} />
      <InputRadio className="ms-4 mb-2" name="settings.mapsTheme" value="dark" label="Dark" register={register} />
      <InputRadio className="ms-4 mb-2" name="settings.mapsTheme" value="night" label="Night" register={register} />
      <InputRadio
        className="ms-4 mb-2"
        name="settings.mapsTheme"
        value="aubergine"
        label="Aubergine"
        register={register}
      />
      <h5 className="mt-4">Claims</h5>
      <div className="ms-4 mb-2">
        <Form.Check
          type="switch"
          {...register('settings.stickyReimbursement', { required: false })}
          label="Keep reimbursement section visible when editing"
          className="my-2"
        />
        <Form.Check
          type="switch"
          {...register('settings.stickySaveButton', { required: false })}
          label="Keep save button visible when editing a claim"
          className="my-2"
        />
        <Form.Check
          type="switch"
          {...register('settings.showReimbursementDetails', { required: false })}
          label="Show reimbursement calculation details"
          className="mt-2 mb-4"
        />
      </div>
      <h5 className="mt-4">Call Recordings</h5>
      <div className="ms-4 mb-2">
        <Form.Check
          type="switch"
          {...register('settings.recordingAutoPlay', { required: false })}
          label="Auto-play recordings"
          className="my-2"
        />
        <Select name="settings.recordingPlaybackSpeed" label="Default Playback Speed" register={register}>
          <option value="0.25">0.25x</option>
          <option value="0.50">0.50x</option>
          <option value="0.75">0.75x</option>
          <option value="1.00">Normal</option>
          <option value="1.25">1.25x</option>
          <option value="1.50">1.50x</option>
          <option value="1.75">1.75x</option>
          <option value="2.00">2.00x</option>
        </Select>
      </div>
    </>
  )
}

export default UserSettingsForm
