import React from 'react'
import { FontAwesomeIcon } from '@abs-warranty/react-fontawesome'
import { faCommentAltSmile } from '@abs-warranty/pro-solid-svg-icons/faCommentAltSmile'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useTheme } from '../modules/theme'
import SignInWithMicrosoft from './sign-in-with-microsoft'
import { queryToString } from '../utils/query-to-string'

function SignInCard() {
  const router = useRouter()
  const { isThemeDark } = useTheme()

  const [isClientSide, setIsClientSide] = useState(false)

  // unfortunately necessary to prevent the sign in with microsoft button from incorrectly rendering server side
  useEffect(() => {
    // set timeout for a nice fade in effect while avoiding a flash of giant icons
    setTimeout(() => setIsClientSide(true), 250)
  }, [])

  return (
    <Row className="justify-content-center">
      <Col sm={6} className={`d-flex justify-content-center pt-5 fade ${isClientSide ? 'show' : ''}`}>
        <Card>
          <Card.Body className="text-center my-4">
            <Card.Title className="mb-4 display-6">
              <FontAwesomeIcon icon={faCommentAltSmile} className="me-2" />
            </Card.Title>
            <h1 className="mb-5 mx-5 display-6">Welcome back!</h1>
            <Card.Text className="d-flex flex-column">
              <a
                href={`/api/v3/auth/signin?returnTo=${encodeURIComponent(queryToString(router.query.returnTo) ?? '/')}`}
                className="mb-4"
              >
                {isClientSide && <SignInWithMicrosoft dark={isThemeDark} />}
              </a>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default SignInCard
