import { useEffect, useState } from 'react'
import { useUser } from './user'

/** @returns {{ isThemeDark: boolean }} */
export function useTheme() {
  const { user, isUserLoading } = useUser()

  // typeof window to fix server-side rendering bug: window is not defined
  const isDarkDefault =
    (typeof window !== 'undefined' && window.matchMedia('(prefers-color-scheme: dark)').matches) || false

  // expose state variable for dark mode, default to browser/OS preference
  const [isThemeDark, setThemeDark] = useState(isDarkDefault)

  /**
   * Respond to browser/OS preference changes
   * @param {boolean} isDark
   */
  function handleMediaQueryChange(isDark) {
    // user settings override browser/OS settings
    if (user?.settings?.theme === 'dark') isDark = true
    if (user?.settings?.theme === 'light') isDark = false

    // set body class
    document.body.className = isDark ? 'theme-dark' : ''

    // update state
    setThemeDark(isDark)
  }

  useEffect(() => {
    if (isUserLoading) return

    // init on page load
    handleMediaQueryChange(isDarkDefault)

    // matchMedia handler method signature is awkward, so use this helper function to simplify things
    const handler = ({ matches }) => handleMediaQueryChange(matches)

    // listen for changes to browser / OS settings
    let mediaQueryThemeDark = window.matchMedia('(prefers-color-scheme: dark)')
    mediaQueryThemeDark.addEventListener('change', handler)

    // cleanup: remove listener
    return () => mediaQueryThemeDark.removeEventListener('change', handler)
  }, [isUserLoading]) // edge case where page loads before user, update settings once user loads

  // react to swr mutations, e.g. when user chooses a new theme
  useEffect(() => {
    if (!user?.settings) return
    handleMediaQueryChange(isDarkDefault)
  }, [user.settings])

  return { isThemeDark }
}
