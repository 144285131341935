import { faClock } from '@abs-warranty/pro-solid-svg-icons/faClock'
import { faCogs } from '@abs-warranty/pro-duotone-svg-icons/faCogs'
import { faFileCheck } from '@abs-warranty/pro-solid-svg-icons/faFileCheck'
import { faPhoneSlash } from '@abs-warranty/pro-solid-svg-icons/faPhoneSlash'
import { faPhone } from '@abs-warranty/pro-duotone-svg-icons/faPhone'
import { faPlus } from '@abs-warranty/pro-regular-svg-icons/faPlus'
import { faSync } from '@abs-warranty/pro-solid-svg-icons/faSync'
import { faTire } from '@abs-warranty/pro-duotone-svg-icons/faTire'
import { faTireRugged } from '@abs-warranty/pro-duotone-svg-icons/faTireRugged'
import { faToolbox } from '@abs-warranty/pro-duotone-svg-icons/faToolbox'
import { faUtilityPoleDouble } from '@abs-warranty/pro-solid-svg-icons/faUtilityPoleDouble'
import { faCar } from '@abs-warranty/pro-duotone-svg-icons/faCar'
import { faLocationCheck } from '@abs-warranty/pro-solid-svg-icons/faLocationCheck'
import { faEdit } from '@abs-warranty/pro-regular-svg-icons/faEdit'
import { faRabbit } from '@abs-warranty/pro-solid-svg-icons/faRabbit'
import { faLightEmergencyOn } from '@abs-warranty/pro-solid-svg-icons/faLightEmergencyOn'
import { faBan } from '@abs-warranty/pro-solid-svg-icons/faBan'
import { faUsers } from '@abs-warranty/pro-solid-svg-icons/faUsers'
import { faBoxArchive } from '@abs-warranty/pro-solid-svg-icons/faBoxArchive'
import { faMicrophoneLines } from '@abs-warranty/pro-regular-svg-icons/faMicrophoneLines'

/** @param {import('../lib/sitemap').Sitemap} sitemap */
function admin(sitemap) {
  // /admin
  const admin = sitemap.add({ href: 'admin', icon: faCogs, label: 'Portal Administration' })

  // /admin/tire-protection
  const tireProtection = sitemap.add({
    parent: admin,
    href: 'tire-protection',
    icon: faTireRugged,
    label: 'Tire Protection',
    description: 'Create and configure road hazard warranties',
  })

  // /admin/tire-protection/products
  const products = sitemap.add({
    parent: tireProtection,
    href: 'products',
    icon: faFileCheck,
    label: 'Products',
    badge: 'Preview',
    description: 'Create and configure rules which define terms and conditions',
  })

  // /admin/tire-protection/products/new
  sitemap.add({ parent: products, href: 'new', icon: faPlus, label: 'New Product' })
  // /admin/tire-protection/products/:slug
  const product = sitemap.add({ parent: products })
  // /admin/tire-protection/products/:slug/edit
  sitemap.add({ parent: product, href: 'edit', icon: faFileCheck, label: 'Edit Product' })

  // /admin/tire-protection/programs
  const programs = sitemap.add({
    parent: tireProtection,
    href: 'programs',
    icon: faTire,
    label: 'Programs',
    badge: 'Preview',
    description: 'View warranty programs',
  })
  // /admin/tire-protection/programs/new
  sitemap.add({ parent: programs, href: 'new', icon: faPlus, label: 'New Program' })
  // /admin/tire-protection/programs/:slug
  sitemap.add({ parent: programs })

  // /admin/advanced
  const advanced = sitemap.add({
    parent: admin,
    href: 'advanced',
    icon: faToolbox,
    label: 'Advanced',
    description: 'Less commonly used system utilities',
  })
  // /admin/advanced/sync-reports
  sitemap.add({ parent: advanced, href: 'sync-reports', icon: faSync, label: 'Sync Reports' })

  // /admin/advanced/vehicles
  const vehicles = sitemap.add({ parent: advanced, href: 'vehicles', icon: faCar, label: 'Vehicles' })
  // /admin/advanced/vehicles/new
  sitemap.add({ parent: vehicles, href: 'new', icon: faPlus, label: 'New Vehicle' })
  const vehicle = sitemap.add({ parent: vehicles, fake: true })
  // /admin/advanced/vehicles/:slug/edit
  sitemap.add({ parent: vehicle, href: 'edit', icon: faPlus, label: 'Edit Vehicle' })

  // /admin/advanced/verify-places
  sitemap.add({ parent: advanced, href: 'verify-places', icon: faLocationCheck, label: 'Verify Places' })

  // /admin/twilio
  const twilio = sitemap.add({
    parent: admin,
    href: 'twilio',
    label: 'Twilio',
    icon: faPhone,
    description: 'Twilio phone system settings and utilities',
    fake: true,
  })

  const blocklist = sitemap.add({
    parent: twilio,
    href: 'blocklist',
    icon: faBan,
    label: 'Blocklist',
    description: 'Block incoming calls from a phone number',
  })

  const blocklistTn = sitemap.add({ parent: blocklist, fake: true })

  sitemap.add({
    parent: blocklistTn,
    href: 'edit',
    icon: faEdit,
    label: 'Edit',
  })

  sitemap.add({
    parent: twilio,
    href: 'call-archive',
    icon: faBoxArchive,
    label: 'Call Archive',
    description: 'Search call history and listen to call recordings (July 2022 - October 2024)',
  })

  sitemap.add({
    parent: twilio,
    href: 'call-history',
    icon: faPhone,
    label: 'Call History',
    description: 'Search call history and listen to call recordings',
  })

  sitemap.add({
    parent: twilio,
    href: 'call-killer',
    icon: faPhoneSlash,
    label: 'Call Killer',
    description: 'End a call currently in progress by Call SID',
  })

  sitemap.add({
    parent: twilio,
    href: 'disaster-recovery',
    icon: faLightEmergencyOn,
    label: 'Disaster Recovery',
    description: 'Disable queues and play a message during an outage',
  })

  sitemap.add({
    parent: twilio,
    href: 'hours-of-operation',
    icon: faClock,
    label: 'Hours of Operation',
    description: 'Configure operating hours and holidays',
  })

  // /admin/twilio/phone-numbers
  const phoneNumbers = sitemap.add({
    parent: twilio,
    href: 'phone-numbers',
    icon: faUtilityPoleDouble,
    label: 'Phone Numbers',
    description: 'Configure phone numbers',
  })

  // /admin/twilio/phone-lines/:slug
  const phoneNumber = sitemap.add({ parent: phoneNumbers })

  // /admin/twilio/phone-lines/:slug/edit
  sitemap.add({
    parent: phoneNumber,
    href: 'edit',
    icon: faEdit,
    label: 'Edit Phone Number',
  })

  sitemap.add({
    parent: twilio,
    href: 'recordings',
    icon: faMicrophoneLines,
    label: 'Recordings',
  })

  const speedDial = sitemap.add({
    parent: twilio,
    href: 'speed-dial',
    icon: faRabbit,
    label: 'Speed Dial',
    description: 'Configure Portal Phone speed dial',
  })

  const speedDialTn = sitemap.add({ parent: speedDial, fake: true })

  sitemap.add({
    parent: speedDialTn,
    href: 'edit',
    icon: faEdit,
    label: 'Edit',
  })

  // /admin/twilio
  const general = sitemap.add({
    parent: admin,
    href: 'general',
    label: 'General',
    icon: faPhone,
    description: 'General Portal configuration',
    fake: true,
  })

  const users = sitemap.add({
    parent: general,
    href: 'users',
    label: 'Users',
    icon: faUsers,
    description: 'Add / Edit Portal users',
  })

  const user = sitemap.add({ parent: users, fake: true })

  sitemap.add({
    parent: user,
    href: 'edit',
    icon: faEdit,
    label: 'Edit',
  })
}

export { admin }
