import { FetchError } from './fetch-error'

/**
 * Wrapper to return a fetcher function that will throw an error if http request is not "ok"
 * Pass dependency array to wait for variables to exist before fetching - useful when chaining the output of one
 * useSWR into another or when waiting query params from the router to exist to construct the API endpoint
 *
 * @param {RequestInit} config
 * @param {Array<any>} [dependencies] optionally check truthiness of each array element before making fetch request
 * @returns {(url: URL | string) => Promise<any>}
 */
function fetcher(config = { credentials: 'same-origin' }, dependencies) {
  // if dependency array exists, make sure every value is truthy
  if (Array.isArray(dependencies) && !dependencies.every(v => !!v)) {
    // one or more dependencies is not ready, return dummy callback
    return async () => {}
  }
  return async url => {
    const res = await fetch(url, config)

    const json = res.headers.get('content-type')?.startsWith('application/json') ? await res.json() : await res.text()

    // throw an error if http status not 2xx
    if (!res.ok) {
      throw new FetchError({ message: json?.message, status: res.status, info: json })
    }

    return json
  }
}

export default fetcher
