import { FontAwesomeIcon } from '@abs-warranty/react-fontawesome'
import { faCircleUser } from '@abs-warranty/pro-duotone-svg-icons/faCircleUser'
import React, { useState } from 'react'
import { useTheme } from '../../modules/theme'

/**
 * Display Microsoft GraphAPI user profile photo if available or default to an icon
 *
 * @returns {JSX.Element}
 */
function UserPhoto() {
  const [isPhoto, setPhoto] = useState(false)

  const { isThemeDark } = useTheme()

  return isPhoto ? (
    <img
      src="/api/v3/auth/photo?size=360x360"
      alt="Profile Photo"
      height="48"
      width="48"
      className="rounded-circle border border-1"
    />
  ) : (
    // 3x size is 48px
    <>
      <FontAwesomeIcon icon={faCircleUser} size="3x" className={isThemeDark ? 'text-sunset' : 'text-primary'} />
      <img
        src="/api/v3/auth/photo?size=360x360"
        alt="Profile Photo"
        height="0"
        width="0"
        hidden
        onLoad={() => {
          setPhoto(true)
        }}
      />
    </>
  )
}

export default UserPhoto
