import React, { useState } from 'react'
import BurntToast from '@abs-warranty/next-components/burnt-toast'

/**
 * Module for displaying error toasts. Includes both UI components and helper functions.
 *
 * To use:
 * ```
 * import { useError } from 'modules/error'
 *
 * function SomeComponent() {
 *   const { Error, setError } = useError()
 *
 *   function thrower() {
 *    try {
 *      throw new Error()
 *    } catch (err) {
 *      setError({error: err, title: 'Toast Title', friendly: 'An error has occurred'})
 *      console.error(err.stack)
 *    }
 *   }
 *
 *   return (
 *     <>
 *       <Error />
 *     </>
 *   )
 * }
 * ```
 * @returns {error}
 */
export function useError() {
  /** @type {[errorMessage | undefined | null | false, React.Dispatch<React.SetStateAction<errorMessage | undefined | null | false>>]} */
  const [error, setError] = useState()

  function Error() {
    return (
      <>
        {error && (
          <BurntToast title={error.title} onClose={() => setError(null)} show={!!error}>
            <p>{error.friendly}</p>
            <p className="mb-0">
              {error.error?.statusCode} {error.error?.message}
            </p>
          </BurntToast>
        )}
      </>
    )
  }

  return {
    Error,
    setError,
  }
}

/**
 * @typedef {object} errorMessage
 * @property {string} title
 * @property {string} friendly user-friendly error message
 * @property {import('@abs-warranty/http-errors').HttpError} [error]
 */

/**
 * @typedef {object} error
 * @property {() => JSX.Element} Error
 * @property {React.Dispatch<React.SetStateAction<errorMessage | undefined | null | false>>} setError
 */
