import { faEdit } from '@abs-warranty/pro-regular-svg-icons/faEdit'
import { faMapLocationDot } from '@abs-warranty/pro-regular-svg-icons/faMapLocationDot'
import { faPlus } from '@abs-warranty/pro-regular-svg-icons/faPlus'
import { faRoadCircleExclamation } from '@abs-warranty/pro-regular-svg-icons/faRoadCircleExclamation'
import { faShop } from '@abs-warranty/pro-regular-svg-icons/faShop'
import { faTire } from '@abs-warranty/pro-regular-svg-icons/faTire'

/** @param {import('../lib/sitemap').Sitemap} sitemap */
function shops(sitemap) {
  // /shops
  const shops = sitemap.add({
    href: 'shops',
    icon: faShop,
    label: 'Shops',
    description: 'View and manage shops on a program',
  })

  // /map
  sitemap.add({
    href: 'map',
    icon: faMapLocationDot,
    label: 'Map',
    description: 'Search for shops on a map',
  })

  // /shops/new
  sitemap.add({
    parent: shops,
    href: 'new',
    icon: faPlus,
    label: 'New Shop',
  })

  // View a shop
  // /shops/:slug
  const shop = sitemap.add({
    parent: shops,
    icon: faShop,
    label: 'View',
  })

  // /shops/:slug/edit
  sitemap.add({
    parent: shop,
    href: 'edit',
    icon: faEdit,
    label: 'Edit Shop',
  })

  // /shops/:slug/parts
  sitemap.add({
    parent: shop,
    href: 'parts',
    icon: faTire,
    label: 'View Parts',
  })

  // /shops/:slug/claims
  sitemap.add({
    parent: shop,
    href: 'claims',
    icon: faRoadCircleExclamation,
    label: 'View Claims',
  })
}

export { shops }
