import { faCars } from '@abs-warranty/pro-regular-svg-icons/faCars'
import { faFileInvoiceDollar } from '@abs-warranty/pro-regular-svg-icons/faFileInvoiceDollar'
import { faAddressCard } from '@abs-warranty/pro-regular-svg-icons/faAddressCard'
import { faEdit } from '@abs-warranty/pro-regular-svg-icons/faEdit'

/** @param {import('../lib/sitemap').Sitemap} sitemap */
function motorClubPrograms(sitemap) {
  // /motor-club
  const motorClub = sitemap.add({
    href: 'motor-club',
    icon: faCars,
    label: 'Motor Club Programs',
    fake: true,
    description: 'View and edit motor club claims submitted through partner websites',
  })

  // /motor-club/:slug
  const program = sitemap.add({
    parent: motorClub,
    icon: faFileInvoiceDollar,
  })

  // /motor-club/:slug/claims
  const programClaims = sitemap.add({
    parent: program,
    href: 'claims',
    icon: faFileInvoiceDollar,
    label: 'Claims',
  })

  // /motor-club/:slug/claims/:slug
  const claim = sitemap.add({
    parent: programClaims,
    icon: faFileInvoiceDollar,
    label: 'View',
  })

  // /motor-club/:slug/claims/:slug/edit
  sitemap.add({
    parent: claim,
    href: 'edit',
    icon: faEdit,
    label: 'Edit Claim',
  })

  // /motor-club/:slug/registrations
  const registrations = sitemap.add({
    parent: program,
    href: 'registrations',
    icon: faAddressCard,
    label: 'Registrations',
  })

  // /motor-club/:slug/registrations/:slug
  sitemap.add({
    parent: registrations,
    icon: faFileInvoiceDollar,
  })

  // /motor-club/:slug/details
  sitemap.add({
    parent: program,
    href: 'details',
    label: 'Program Details',
  })
}

export { motorClubPrograms }
