// TODO: replace with @abs-warranty/http-errors

/**
 * Custom FetchError object is thrown when `fetcher` encounters an error
 * @class
 * @extends {Error}
 */
class FetchError extends Error {
  /**
   * @param {object} params
   * @param {string} [params.message]
   * @param {number} [params.status]
   * @param {any} [params.info]
   */
  constructor({ message = 'An error occurred while fetching the data', status = 500, info }) {
    super(message)
    this.name = 'FetchError'
    this.status = status
    this.info = info
  }
}

module.exports = { FetchError }
