/**
 * Convert URL slugs to camel case names for use as sitemap keys. Defaults to '$root' if no string provided.
 *
 * @param {string} str snake-case string to convert to camelCase
 * @returns {string}
 */
function snakeToCamel(str) {
  // extra check because javascript plays fast and loose with types, but that causes problems here
  if (typeof str !== 'string') throw new Error(`🐍🐪 \`str\` must be a string, got type ${typeof str} for value ${str}`)
  return str.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '')) || '$root'
}

export { snakeToCamel }
