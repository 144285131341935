// TODO: merge with next-components/utils/format-phone -- utility is the same except this formats 'Private' numbers.`

/**
 * Convert E.164 '+15551234567' or any other 10-digit or 11-digit number to '(555) 123-4567'
 * If number doesn't conform to North American format, the original string is returned
 * If a falsy value is passed, an empty string is returned
 *
 * @param {string} [phoneNumber] phone number
 * @returns {string} friendly formatted phone number
 */
function formatPhone(phoneNumber) {
  // return empty string if undefined for type safety convenience
  if (!phoneNumber) return ''

  // format private numbers
  if (phoneNumber === '+266696687') return 'Private'

  // remove any existing non-digit formatting
  let tn = phoneNumber.replace(/\D/g, '')

  // if 11 digits starting with 1, strip the 1 (e.g. `phoneNumber` was already E.164 formatted starting with +1)
  tn = tn.length === 11 && tn.startsWith('1') ? tn.substring(1) : tn

  // return original value if we ended up with more than 10 digits
  if (tn.length !== 10) return phoneNumber

  // return formatted phone number
  return `(${tn.substring(0, 3)}) ${tn.substring(3, 6)}-${tn.substring(6)}`
}

export default formatPhone
