import { SaveBtn } from '@abs-warranty/next-components/form'
import { HttpError } from '@abs-warranty/http-errors'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { useError } from './error'

/**
 * Module for making POST requests to a specified endpoint. Includes both UI components and helper functions.
 *
 * To use:
 * ```
 * import { useSubmit } from 'modules/submit'
 *
 * function SomeComponent() {
 *   const { onSubmit, SubmitBtn, Error } = useSubmit({
 *     uri: {
 *       path: '/api/v3/...',
 *       redirect: '/some/page',
 *       method: 'POST', // optional, default to POST
 *       callback: () => {}
 *     },
 *     error: {
 *       title: 'Unable To...',
 *       friendly: 'We were unable to ...',
 *     }
 *   })
 *
 *   return (
 *     <>
 *       <Error />
 *       <form onSubmit={handleSubmit(onSubmit)}>
 *         <SubmitBtn />
 *       </form>
 *     </>
 *   )
 * }
 * ```
 * @param {object} params
 * @param {uri} params.uri
 * @param {import('./error').errorMessage} params.error
 * @param {object} [params.defaultValues={}] TODO: what is this?
 * @param {(formData: import('react-hook-form').FieldValues) => Promise<import('react-hook-form').FieldValues>} [params.marshaller] Function to be called on form data before submission
 * @param {(formData: import('react-hook-form').FieldValues) => boolean} [params.validator]
 * @returns {submit}
 */
export function useSubmit({ uri, error, defaultValues = {}, marshaller, validator }) {
  const router = useRouter()
  const { Error, setError } = useError()
  const [isSaving, setIsSaving] = useState(false)

  /** @type {import('react-hook-form').SubmitHandler<import('react-hook-form').FieldValues>} */
  async function onSubmit(formData) {
    // if validator function is passed, it must return true to continue
    if (!!validator) if (!validator(formData)) return

    formData = { ...defaultValues, ...formData }
    setIsSaving(true)
    try {
      if (marshaller) formData = await marshaller(formData)

      const res = await fetch(uri.path, {
        method: uri.method ?? 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })

      const response = res.headers.get('content-type')?.startsWith('application/json')
        ? await res.json()
        : await res.text()

      if (!res.ok) {
        throw new HttpError(res.status, response.message ?? response.error ?? JSON.stringify(response))
      }

      if (uri.callback) {
        // TODO: move to parent object -- this isn't part of the `uri`.
        // a callback function takes priority
        await uri.callback(response)
        setIsSaving(false)
      } else if (uri.redirect) {
        // This is the standard procedure - redirect to the passed page after successful submission
        if (!uri.useNewId) return router.push(uri.redirect)

        // Otherwise - use the id of the newly created resource to redirect to the new resource
        router.push(`${uri.redirect}/${response._id}`)
      }
    } catch (err) {
      setError({ error: err, title: error.title, friendly: error.friendly })
      console.error(err)
      setIsSaving(false)
    }
  }

  /**
   * @callback SubmitBtn
   * @param {SubmitBtnParams} params
   * @returns {JSX.Element}
   */
  function SubmitBtn({ className = '', Btn }) {
    return !!Btn ? <Btn isSaving={isSaving} /> : <SaveBtn isSaving={isSaving} className={`shadow-sm ${className}`} />
  }

  return {
    onSubmit,
    SubmitBtn,
    Error,
    setError,
  }
}

/**
 * @typedef {object} SubmitBtnParams
 * @property {string} [className]
 * @property {JSX.Element} [Btn] pass to use alternative button
 */

/**
 * @typedef {object} submit
 * @property {import('react-hook-form').SubmitHandler<import('react-hook-form').FieldValues>} onSubmit
 * @property {(SubmitBtnParams) => JSX.Element} SubmitBtn
 * @property {() => JSX.Element} Error
 * @property {React.Dispatch<React.SetStateAction<false | import('./error').errorMessage | null | undefined>>} setError
 */

/**
 * @typedef {object} uri
 * @property {string} path
 * @property {string} [redirect] redirect path. required if useNewId = true
 * @property {string} [method]
 * @property {boolean} [useNewId]
 * @property {(any) => Promise<void>} [callback] optional callback function, takes priority over redirect path.
 */
