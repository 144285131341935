import { faFileArrowUp } from '@abs-warranty/pro-duotone-svg-icons/faFileArrowUp'
import { faFileChartColumn } from '@abs-warranty/pro-duotone-svg-icons/faFileChartColumn'
import { faTireRugged } from '@abs-warranty/pro-duotone-svg-icons/faTireRugged'

/** @param {import('../lib/sitemap').Sitemap} sitemap */
function dataIngestion(sitemap) {
  const dataIngestion = sitemap.add({
    href: 'data-ingestion',
    icon: faFileArrowUp,
    label: 'Data Ingestion',
  })

  const tireProtection = sitemap.add({
    parent: dataIngestion,
    href: 'tire-protection',
    icon: faTireRugged,
    label: 'Tire Protection',
  })

  sitemap.add({
    parent: tireProtection,
    href: 'tbc',
    icon: faFileChartColumn,
    label: 'TBC',
  })
}

export { dataIngestion }
