import { Branch } from './branch'

/** @typedef {import('../').pageFn} pageFn */
/** @typedef {import('./page').Page} Page */

class Graft {
  /** @type {Page} */
  #page

  /** @type {pageFn | undefined} */
  #pageFn

  /** @type {Graft | undefined} */
  #child

  /**
   *
   * @param {Object} props
   * @param {Page} props.page
   * @param {pageFn} [props.pageFn]
   * @param {Graft} [props.child]
   */
  constructor({ page, pageFn, child }) {
    this.#page = page
    this.#pageFn = pageFn
    this.#child = child
  }

  get page() {
    return this.#page
  }

  get pageFn() {
    return this.#pageFn
  }

  get child() {
    return this.#child
  }

  /**
   * This Graft cast as a Branch
   * @returns {Branch} */
  toBranch() {
    const branch = new Branch({ page: this.#page, pageFn: this.#pageFn })
    if (this.#child) branch.add(this.#child.toBranch())
    return branch
  }

  toString() {
    return JSON.stringify(
      {
        page: JSON.parse(this.#page.toString()),
        pageFn: this.#pageFn && 'pageFn()',
        child: JSON.parse(this.#child?.toString() ?? '{}'),
      },
      null,
      2
    )
  }
}

export { Graft }
