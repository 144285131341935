import React, { useState } from 'react'
import { Form, Modal, NavDropdown } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@abs-warranty/react-fontawesome'
import { faUserCog } from '@abs-warranty/pro-duotone-svg-icons/faUserCog'
import { useSubmit } from '../../modules/submit'
import UserSettingsForm from '../user-settings-form'

/**
 * @param {object} props
 * @param {import('../../modules/user').user} props.user
 * @param {() => Promise<void>} props.reload
 * @returns {JSX.Element}
 */
function UserSettings({ user, reload }) {
  const [show, setShow] = useState(false)

  /** @ts-ignore: form types are too restrictive @type {{ register: import('react-hook-form').UseFormRegister<import('react-hook-form').FieldValues>, handleSubmit: import('react-hook-form').UseFormHandleSubmit<import('react-hook-form').FieldValues> }} */
  const { register, handleSubmit } = useForm({
    defaultValues: { settings: user.settings },
  })

  const { onSubmit, SubmitBtn, Error } = useSubmit({
    uri: {
      path: '/api/v3/users/internal/me',
      method: 'PATCH',
      callback: async () => {
        reload()
        setShow(false)
      },
    },
    error: {
      title: '',
      friendly: '',
    },
  })

  return (
    <>
      <Error />
      <Modal show={show} onHide={() => setShow(false)}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FontAwesomeIcon icon={faUserCog} fixedWidth className="me-2" />
              Settings
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UserSettingsForm register={register} />
          </Modal.Body>
          <Modal.Footer>
            <SubmitBtn className="me-auto" />
          </Modal.Footer>
        </Form>
      </Modal>
      <NavDropdown.Item onClick={() => setShow(!show)}>
        <FontAwesomeIcon icon={faUserCog} fixedWidth className="me-2" />
        Settings
      </NavDropdown.Item>
    </>
  )
}

export default UserSettings
