import React from 'react'
import { FontAwesomeIcon } from '@abs-warranty/react-fontawesome'
import { faCopyright } from '@abs-warranty/pro-regular-svg-icons/faCopyright'
import { useTheme } from '../../modules/theme'

function Footer() {
  const { isThemeDark } = useTheme()
  return (
    <footer className="bg-light mt-auto pt-3 pb-2 container-fluid d-print-none border-top">
      <ul className="list-inline mb-2">
        <li className="list-inline-item me-2">
          <FontAwesomeIcon icon={faCopyright} /> {new Date().getFullYear()}&nbsp;
          <a
            href="https://www.abswarranty.net/"
            target="_blank"
            rel="noreferrer"
            className={`${isThemeDark ? 'text-light' : 'text-body'} text-decoration-none`}
          >
            Automotive Business Solutions
          </a>
          .
        </li>
        <li className="list-inline-item me-2">All rights reserved.</li>
        <li className="list-inline-item">
          ABS Call Center Portal &nbsp;
          <a
            href="https://github.com/abs-warranty/interchange/releases"
            target="_blank"
            rel="noreferrer"
            className={`${isThemeDark ? 'text-light' : 'text-body'} text-decoration-none`}
          >
            v{process.env.VERSION} ({process.env.GIT_SHORT_SHA})
          </a>
        </li>
      </ul>
    </footer>
  )
}

export default Footer
