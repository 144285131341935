import '../styles/bootstrap.scss'
import '../styles/global.scss'
import '../styles/theme-dark.scss'
import { useTheme } from '../modules/theme'
import React from 'react'

function MyApp({ Component, pageProps }) {
  // sets document.body class; listens for changes to browser/OS theme preference
  useTheme()

  return <Component {...pageProps} />
}

export default MyApp
