import { faEngine } from '@abs-warranty/pro-regular-svg-icons/faEngine'
import { faTools } from '@abs-warranty/pro-regular-svg-icons/faTools'
import { faImage } from '@abs-warranty/pro-regular-svg-icons/faImage'

/** @param {import('../lib/sitemap').Sitemap} sitemap */
function tools(sitemap) {
  // /tools (fake)
  const tools = sitemap.add({
    href: 'tools',
    icon: faTools,
    label: 'Tools',
    fake: true,
    description: 'Miscellaneous utilities',
  })

  // /tools/carfax
  sitemap.add({
    parent: tools,
    href: 'carfax',
    icon: faEngine,
    label: 'Carfax',
  })

  // /tools/heic-converter
  sitemap.add({
    parent: tools,
    href: 'heic-converter',
    icon: faImage,
    label: 'HEIC Converter',
  })
}

export { tools }
