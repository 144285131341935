import { faCarWrench } from '@abs-warranty/pro-regular-svg-icons/faCarWrench'
import { faFileInvoiceDollar } from '@abs-warranty/pro-regular-svg-icons/faFileInvoiceDollar'
import { faEdit } from '@abs-warranty/pro-regular-svg-icons/faEdit'

/** @param {import('../lib/sitemap').Sitemap} sitemap */
function localLaborPrograms(sitemap) {
  // /local-labor
  const localLabor = sitemap.add({
    href: 'local-labor',
    icon: faCarWrench,
    label: 'Local Labor Programs',
    fake: true,
    description: 'View local labor claims submitted online via MyABS',
  })

  // /local-labor/:slug
  const program = sitemap.add({
    parent: localLabor,
    icon: faFileInvoiceDollar,
  })

  // /local-labor/:slug/claims
  const programClaims = sitemap.add({
    parent: program,
    href: 'claims',
    icon: faFileInvoiceDollar,
    label: 'Claims',
  })

  // /local-labor/:slug/claims/:slug
  const claim = sitemap.add({
    parent: programClaims,
    icon: faFileInvoiceDollar,
    label: 'View',
  })

  // /local-labor/:slug/claims/:slug/edit
  sitemap.add({
    parent: claim,
    href: 'edit',
    icon: faEdit,
    label: 'Edit Claim',
  })

  // /local-labor/:slug/details
  sitemap.add({
    parent: program,
    href: 'details',
    label: 'Program Details',
  })
}

export { localLaborPrograms }
